import { Router } from "@angular/router";
import { AmplifyService } from "aws-amplify-angular";
import * as i0 from "@angular/core";
import * as i1 from "aws-amplify-angular/dist/src/providers/amplify.service";
import * as i2 from "@angular/router";
var AuthGuardService = /** @class */ (function () {
    function AuthGuardService(amplifyService, router) {
        this.amplifyService = amplifyService;
        this.router = router;
    }
    AuthGuardService.prototype.canActivate = function () {
        var _this = this;
        console.log("AuthGuard#canActivate called");
        return this.amplifyService
            .auth()
            .currentAuthenticatedUser()
            .then(function (user) { return true; })
            .catch(function (err) {
            _this.router.navigateByUrl("");
            return false;
        });
    };
    AuthGuardService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthGuardService_Factory() { return new AuthGuardService(i0.ɵɵinject(i1.AmplifyService), i0.ɵɵinject(i2.Router)); }, token: AuthGuardService, providedIn: "root" });
    return AuthGuardService;
}());
export { AuthGuardService };
