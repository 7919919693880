export const environment = {
  production: true,
  env: 'prod',
  inventra: {
    access: {
      api_url: 'https://vnsh6okz81.execute-api.us-east-2.amazonaws.com/prod/'
    },
    profile: {
      api_url: 'https://bsfz5haevf.execute-api.us-east-2.amazonaws.com/prod/'
    },
    project: {
      api_url: 'https://hqqellak5c.execute-api.us-east-2.amazonaws.com/prod/'
    },
    company: {
      api_url: 'https://j7eky5rcf1.execute-api.us-east-2.amazonaws.com/prod/'
    },
    gin: {
      api_url: 'https://pq9w2rpidb.execute-api.us-east-2.amazonaws.com/prod/'
    },
    grn: {
      api_url: 'https://fgejccyom9.execute-api.us-east-2.amazonaws.com/prod/'
    },
    numbers: {
      api_url: 'https://94xdp28qe4.execute-api.us-east-2.amazonaws.com/prod/'
    },
    product_type: {
      api_url: 'https://omdffsmz73.execute-api.us-east-2.amazonaws.com/prod/'
    },
    product: {
      api_url: 'https://rh4gnnyzx1.execute-api.us-east-2.amazonaws.com/prod/'
    },
    project_stage: {
      api_url: 'https://hqqellak5c.execute-api.us-east-2.amazonaws.com/prod/'
    },
    project_status: {
      api_url: 'https://hqqellak5c.execute-api.us-east-2.amazonaws.com/prod/'
    },
    stock_report: {
      api_url: 'https://w5ztw61hi6.execute-api.us-east-2.amazonaws.com/prod/'
    },
    roles: {
      api_url: 'https://u9kj36ekp3.execute-api.us-east-2.amazonaws.com/prod/'
    },
    attributes: {
      api_url: 'https://sx40i27g9h.execute-api.us-east-2.amazonaws.com/prod/'
    },
    image: {
      api_url: 'https://g02ttj3j05.execute-api.us-east-2.amazonaws.com/prod/'
    },
    store: {
      api_url: 'https://z43nm5ykw1.execute-api.us-east-2.amazonaws.com/prod/'
    },
    location: {
      api_url: 'https://lzvgbx7345.execute-api.us-east-2.amazonaws.com/prod/'
    },
    suplier: {
      api_url: 'https://6jis0eh37c.execute-api.us-east-2.amazonaws.com/prod/'
    },
    uom: {
      api_url: 'https://344ji03nl1.execute-api.us-east-2.amazonaws.com/prod/'
    }
  },
  // Dashbord Widgets
  dashboard: {
    revenue: {
      active: true
    },
    sales: {
      active: true
    },
    users: {
      active: true
    },
    month_satats: {
      active: true
    },
    feeds: {
      active: true
    },
    market_records: {
      active: true
    },
  },

  // Side Menu Config
  sideMenu: {
    dashboard: {
      active: true
    },
    products: {
      active: true
    },
    inventory: {
      active: true,
      gr: {
        active: true
      },
      gi: {
        active: true
      },
      cr: {
        active: true
      }
    },
    marketplace: {
      active: false,
      marketplaces: {
        active: false
      },
      orders: {
        active: false
      },
      customers: {
        active: false
      }
    },
    projects: {
      active: true
    },
    reports: {
      active: true,
      sr: {
        active: true
      },
      asr: {
        active: true
      },
      slr: {
        active: true
      },
      tsr: {
        active: true
      },
      pr: {
        active: true
      }
      
    },
    user: {
      active: true,
      users: {
        active: true
      },
      roles: {
        active: true
      }
    },
    system: {
      active: true,
      company: {
        active: true
      },
      stores: {
        active: true
      },
      locations: {
        active: true
      },
      suppliers: {
        active: true
      },
      uom: {
        active: true
      },
      attributes: {
        active: true
      },
      project: {
        active: true
      },
      customerReturn: {
        active: true
      }
    },
  }

};
