import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService} from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { Auth } from 'aws-amplify';

@Component({
  selector: 'app-page-confirm-sign-up',
  templateUrl: './page-confirm-sign-up.component.html',
  styleUrls: ['./page-confirm-sign-up.component.css']
})
export class PageConfirmSignUpComponent implements OnInit {
  email="";
  code="";

  constructor(private router: Router, private spinner: NgxSpinnerService, private toastr: ToastrService, private activatedRoute: ActivatedRoute) { }

  ngOnInit() {
    let id = this.activatedRoute.snapshot.paramMap.get('id');
    console.log(id);
    this.email = id;
  }

  onSubmit(){
    var username = this.email;
    var code = this.code;
    Auth.confirmSignUp(username, code, {
      // Optional. Force user confirmation irrespective of existing alias. By default set to True.
      forceAliasCreation: true    

    }).then(data => {
      console.log(data);
      this.router.navigate(['/auth/signin']);
    })
      .catch(err => {
        console.log(err);
        this.toastr.error(err.message,'Error');
        this.code="";
      });
    
 
  }

  resendCode(){
    var username = this.email;
    Auth.resendSignUp(username).then(() => {
        console.log('code resent successfully');
        this.toastr.success('Code resent successfully','Success');
    }).catch(e => {
        console.log(e);
        this.toastr.error(e.message,'Error');
    });

  }

}
