<!-- <div id="wrapper">
	<div class="vertical-align-wrap">
		<div class="vertical-align-middle auth-main">
			<div class="auth-box">
				<div class="top">
					
				</div>
				<div class="card">
					<div class="header">
						<p class="lead">Login to your account</p>
					</div>
					<div class="body">
						<form class="form-auth-small" #loginForm="ngForm" (ngSubmit)="onSubmit()">
							<div class="form-group">
								<label for="signin-email" class="control-label sr-only">Email</label>
								<input type="email" class="form-control" id="signin-email" value="user@domain.com" placeholder="Email">
							</div>
							<div class="form-group">
								<label for="signin-password" class="control-label sr-only">Password</label>
								<input type="password" class="form-control" id="signin-password" value="thisisthepassword" placeholder="Password">
							</div>
							<div class="form-group clearfix">
								<label class="fancy-checkbox element-left">
									<input type="checkbox">
									<span>Remember me</span>
								</label>
							</div>
							<button type="submit" class="btn btn-primary btn-lg btn-block">LOGIN</button>
							<div class="bottom">
								<span class="helper-text m-b-10"><i class="fa fa-lock"></i>&nbsp;<a [routerLink]="['/auth/forgot']">Forgot
										password?</a></span>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	</div>
</div> -->


<div id="wrapper" id="signin">
	<div class="vertical-align-wrap">
		<div class="container" >	
			<div class="row">
				<div class="col-md-1"></div>
				<div class="col-md-10 col-sm-7 main-content">
					<div class="row">
						<div class="col-md-6 col-sm-6 leftImage">
						</div>
						<div class="col-md-6 col-sm-6 rightForm">
							
							<div class="col-12"><img src="/assets/images/signin_logo.jpg" width="100%"></div>
							<div class="col-12 header">Sign in</div>
							<form class="col-12 form-auth-small" #loginForm="ngForm" (ngSubmit)="onSubmit()">
								<div class="form-group">
									<i class="fa fa-user fa-2x cus"></i>
									<input type="text" class="form-control" id="signin-email" [ngModelOptions]="{standalone: true}" [(ngModel)]="email" placeholder="Email" required>
								</div>
		
								<div class="form-group">
									<i class="fa fa-lock fa-2x cus"></i>
									<input type="password" class="form-control" id="signin-password"  [ngModelOptions]="{standalone: true}" [(ngModel)]="password" placeholder="Password" required>
								</div>

								<div class="form-group" *ngIf="resetpaswrd">
									<i class="fa fa-lock fa-2x cus"></i>
									<input type="password" class="form-control" id="signin-password"  [ngModelOptions]="{standalone: true}" [(ngModel)]="new_password" placeholder="New Password">
								</div>

								<div class="validation-message">{{signinerrormsg}}</div>
		
								<div class="form-group clearfix">
									<label class="fancy-checkbox element-left">
										<input type="checkbox">
										<span>Remember me</span>
									</label>
								</div>
							
								<button type="submit" class="btn btn-primary btn-lg btn-block">Sign In</button>
								
								<div class="bottom bottomspace">
									<span class="helper-text m-b-10"><i class="fa fa-lock"></i>
									&nbsp;<a [routerLink]="['/auth/forgot']">Forgot password?</a>
									<!-- &nbsp;<a (click)="resetNewPassword()" *ngIf="resetpaswrd" >reset password?</a -->
									</span>
								</div>
								<div class="bottom">
									<span class="helper-text m-b-10"><i class="fa fa-user-plus"></i>&nbsp;<a [routerLink]="['/auth/signup']">Create account</a></span>
								</div>
							</form>
						</div>
					</div>
				</div>
				<div class="col-md-1"></div>

			</div>
				
		</div>
</div>

</div>



