<!-- <div id="wrapper">
	<div class="vertical-align-wrap">
		<div class="vertical-align-middle auth-main">
			<div class="auth-box">
				<div class="top">
					
				</div>
				<div class="card">
					<div class="header">
						<p class="lead">Recover my password</p>
					</div>
					<div class="body">
						<p>Please enter your email address below to receive instructions for resetting password.</p>
						<form class="form-auth-small" #lockForm="ngForm" (ngSubmit)="onSubmit()">
							<div class="form-group">
								<input type="email" class="form-control" id="signup-password" placeholder="Email">
							</div>
							<button type="submit" class="btn btn-primary btn-lg btn-block">RESET PASSWORD</button>
							<div class="bottom">
								<span class="helper-text">Know your password? <a [routerLink]="['/auth/signin']">Login</a></span>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	</div>
</div> -->

<!-- <div id="wrapper">
	<div class="vertical-align-wrap">
		<div class="vertical-align-middle auth-main">
			<div class="auth-box">
				<div class="top">
					
				</div>
				<div class="card">
					<div class="header">
						<p class="lead">Login to your account</p>
					</div>
					<div class="body">
						<form class="form-auth-small" #loginForm="ngForm" (ngSubmit)="onSubmit()">
							<div class="form-group">
								<label for="signin-email" class="control-label sr-only">Email</label>
								<input type="email" class="form-control" id="signin-email" value="user@domain.com" placeholder="Email">
							</div>
							<div class="form-group">
								<label for="signin-password" class="control-label sr-only">Password</label>
								<input type="password" class="form-control" id="signin-password" value="thisisthepassword" placeholder="Password">
							</div>
							<div class="form-group clearfix">
								<label class="fancy-checkbox element-left">
									<input type="checkbox">
									<span>Remember me</span>
								</label>
							</div>
							<button type="submit" class="btn btn-primary btn-lg btn-block">LOGIN</button>
							<div class="bottom">
								<span class="helper-text m-b-10"><i class="fa fa-lock"></i>&nbsp;<a [routerLink]="['/auth/forgot']">Forgot
										password?</a></span>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	</div>
</div> -->


<div id="wrapper" id="forgot-password">
		<div class="vertical-align-wrap">
			<div class="container" >	
				<div class="row">
					<div class="col-md-1"></div>
					<div class="col-md-10 col-sm-7 main-content">
						<div class="row">
							<div class="col-md-6 col-sm-6 leftImage">
							</div>
							<div class="col-md-6 col-sm-6 rightForm">
								
								<div class="col-12"><img src="/assets/images/signin_logo.jpg" width="100%" height=""></div>
								<div class="col-12 header">Reset your password</div>
								<div *ngIf="!resetPassword">
									<form class="col-12 form-auth-small" #loginForm="ngForm" (ngSubmit)="onSubmit()" >
										<div class="form-group">
											<span>You will receive a verification code</span>
										</div>
				
										<div class="form-group">
											<i class="fa fa-user fa-2x cus"></i>
											<input type="email" class="form-control" id="forgot-password-email" [ngModelOptions]="{standalone: true}" [(ngModel)]="email" placeholder="Email">
										</div>

										<div class="validation-message">{{forgotpassworderrormsg}}</div>
									
										<button type="submit" class="btn btn-primary btn-lg btn-block">Submit</button>
	
										<div class="bottom">
											<span class="helper-text m-b-10"><i class="fa fa-sign-in"></i> Back to sign in?&nbsp;<a [routerLink]="['/auth/signin']">Sign in</a></span>
										</div>
			
									</form>
								</div>

								<div *ngIf="resetPassword">
									<form class="col-12 form-auth-small" #loginForm="ngForm" (ngSubmit)="verifyPassword()" >
										<div class="form-group">
											<span>Enter the code you received and set a new password</span>
										</div>
				
										<div class="form-group">
											<i class="fa fa-user fa-2x cus"></i>
											<input type="text" class="form-control" id="forgot-password-code" [ngModelOptions]="{standalone: true}" [(ngModel)]="code" placeholder="Confirmation Code">
										</div>

										<div class="form-group">
											<i class="fa fa-lock fa-2x cus"></i>
											<input type="password" class="form-control" id="forgot-password-password" [ngModelOptions]="{standalone: true}" [(ngModel)]="password" placeholder="New Password">
										</div>

										<div class="validation-message">{{forgotpassworderrormsg}}</div>
									
										<button type="submit" class="btn btn-primary btn-lg btn-block">Verify</button>
	
										<div class="bottom">
											<span class="helper-text m-b-10"><i class="fa fa-code"></i>&nbsp;<a (click)="resendCode()">Resend Code</a></span>
										</div>
			
									</form>
								</div>
	

							</div>
						</div>
					</div>
					<div class="col-md-1"></div>
	
				</div>
					
			</div>
	</div>
	
	</div>
	
	
	
	