import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { AmplifyService } from 'aws-amplify-angular';
import Amplify, { Auth } from 'aws-amplify';
import awsconfig from '../../authentication/services/aws-exports';
Amplify.configure(awsconfig);
import { CognitoUser } from 'amazon-cognito-identity-js';

@Component({
    selector: 'app-page-login',
    templateUrl: './page-login.component.html',
    styleUrls: ['./page-login.component.css']
})
export class PageLoginComponent implements OnInit {

    signedIn: boolean;
    signup: boolean = false;
    resetpaswrd: boolean = false;
    user: any;
    greeting: string;
    authState: any;
    email: string;
    password: string;
    validationData: any;
    userobj: any;
    new_password: string;
    state: any;
    signinerrormsg: string;

    constructor(private router: Router, private spinner: NgxSpinnerService, private toastr: ToastrService, private amplifyService: AmplifyService) {
        this.checkauthstate();
    }

    ngOnInit() {
        //this.resetpaswrd = false;
    }

    checkauthstate() {
        this.amplifyService.authStateChange$
            .subscribe(authState => {
                console.log(authState);
                this.authState = authState;
                this.state = authState.state;
                this.signedIn = authState.state === 'signedIn';
                if (!authState.user) {
                    this.user = null;
                    //this.router.navigate(['/auth/signin']);
                } else {
                    this.user = authState.user;
                    this.router.navigate(['/admin/dashboard/index']);

                }
            });
    }

    onSubmit() {
        
        var email = this.email;
        var password = this.password;
        var newPassword = this.new_password;
        var user = this.userobj;

        //console.log(this.userobj);
        console.log('state', this.state);
        if(this.email == undefined ||  this.email == "" && this.password == undefined || this.password == ""){
            this.signinerrormsg = "Email and Password cannot be empty";

        }
        else if(this.email == undefined || this.email == ""){
            this.signinerrormsg = "Email cannot be empty";
        }
        else if(this.password == undefined || this.password == ""){
            this.signinerrormsg = "Password cannot be empty"
        }
        else{
            this.spinner.show('authentication');

            if (this.state === "requireNewPassword") {
                Auth.signIn(email, password)
                    .then(user => {
                        if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
                            const { requiredAttributes } = user.challengeParam; // the array of required attributes, e.g ['email', 'phone_number']
                            Auth.completeNewPassword(
                                user,               // the Cognito User Object
                                newPassword,       // the new password
                                // OPTIONAL, the required attributes
                                {
                                    //email: 'xxxx@example.com',
                                    //phone_number: '1234567890'
                                }
                            ).then(user => {
                                // at this time the user is logged in if no MFA required
                                console.log(user);
                                // this.spinner.hide('authentication');
                                // this.toastr.success('Sign in successfully', 'Success');
                                this.router.navigateByUrl('/admin/dashboard/index');
                            }).catch(e => {
                                console.log(e);
                                this.spinner.hide('authentication');
    
                                //this.toastr.error(e.message, 'Error');
                                this.signinerrormsg = e.message;
                            });
                        } else {
                            // other situations
                        }
                    }).catch(e => {
                        //console.log(e);
                        this.spinner.hide('authentication');
                        //this.toastr.error(e.message, 'Error');
                        this.signinerrormsg = e.message;
                    });
    
            }
            else {
                Auth.signIn(email, password).then(user => {
                    console.log(user);
                    this.userobj = user;
                    if (user.signInUserSession !== null) {
                        this.spinner.hide('authentication');
                        this.toastr.success('Sign in successfully');
                        this.router.navigateByUrl('/admin/dashboard/index');
                    }
                    else {
                        this.spinner.hide('authentication');
                        this.resetpaswrd = true;
                        this.toastr.warning('You should reset password');
                    }
    
                })
                    .catch(err => {
                        if (err.code === 'UserNotConfirmedException') {
                            this.router.navigate(['/auth/confirm/', this.email]);
                            this.spinner.hide('authentication');
                        } else {
                            //console.log(err);
                            this.spinner.hide('authentication');
                            //this.toastr.error(err.message, 'Error');
                            this.signinerrormsg = err.message
                        }
                        this.resetpaswrd = false;
                        
                    }
    
                    );
    
    
            }
    

        }
       


    }
}
