import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { AmplifyService } from 'aws-amplify-angular';
import Amplify, { Auth } from 'aws-amplify';
import awsconfig from '../../authentication/services/aws-exports';
Amplify.configure(awsconfig);

@Component({
    selector: 'app-page-register',
    templateUrl: './page-register.component.html',
    styleUrls: ['./page-register.component.css']
})
export class PageRegisterComponent implements OnInit {
    email: any = "";
    password: any = "";
    phone_number = "";
    signuperrormsg: string;
    
    constructor(private router: Router, private spinner: NgxSpinnerService, private toastr: ToastrService) { }

    ngOnInit() {
    }

    onSubmit() {
        var username = this.email;
        var password = this.password;
        var phone_number = this.phone_number;
        Auth.signUp({
            username,
            password,
            attributes: {
                phone_number,   // optional - E.164 number convention
                // other custom attributes 
            },
            validationData: []  //optional
        })
            .then(data => {
                console.log(data);
                this.router.navigate(['/auth/confirm/', this.email]);

            })
            .catch(err => {
                this.signuperrormsg = err.message;
                //console.log(this.signuperrormsg);
               // this.toastr.warning(err.message);

            });

    }

}
