<!-- <div id="wrapper">
	<div class="vertical-align-wrap">
		<div class="vertical-align-middle auth-main">
			<div class="auth-box">
				<div class="top">
					<img src="assets/images/logo-white.svg" alt="Lucid">
				</div>
				<div class="card">
					<div class="header">
						<p class="lead">Create an account</p>
					</div>
					<div class="body">
						<form class="form-auth-small" #registrationForm="ngForm" (ngSubmit)="onSubmit()">
							<div class="form-group">
								<label for="signup-email" class="control-label sr-only">Email</label>
								<input type="email" class="form-control" id="signup-email" placeholder="Your email">
							</div>
							<div class="form-group">
								<label for="signup-password" class="control-label sr-only">Password</label>
								<input type="password" class="form-control" id="signup-password" placeholder="Password">
							</div>
							<button type="submit" class="btn btn-primary btn-lg btn-block">REGISTER</button>
							<div class="bottom">
								<span class="helper-text">Already have an account? <a [routerLink]="['/auth/signin']">Login</a></span>
							</div>
						</form>
						<div class="separator-linethrough"><span>OR</span></div>
						<button class="btn btn-signin-social"><i class="fa fa-facebook-official facebook-color"></i> Sign in with
							Facebook</button>
						<button class="btn btn-signin-social"><i class="fa fa-twitter twitter-color"></i> Sign in with Twitter</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</div> -->

<div id="wrapper" id="signup">
	<div class="vertical-align-wrap">
		<div class="container">
			<div class="row">
				<div class="col-md-1"></div>
				<div class="col-md-10 col-sm-7 main-content">
					<div class="row">
						<div class="col-md-6 col-sm-6 leftImage">
						</div>
						<div class="col-md-6 col-sm-6 rightForm">

							<div class="col-12"><img src="/assets/images/signin_logo.jpg" width="100%"></div>
							<div class="col-12 header">Sign Up</div>

							<form class="col-12 form-auth-small" (ngSubmit)="onSubmit()" #f="ngForm">

								<div class="form-group">
									<i class="fa fa-user fa-2x cus"></i>
									<input type="email" class="form-control" id="signup-email" 
										[ngModelOptions]="{standalone: true}" [(ngModel)]="email" required placeholder="Email">
										
								</div>

								<div class="form-group">
									<i class="fa fa-lock fa-2x cus"></i>
									<input type="password" class="form-control" id="signup-password" 
										[ngModelOptions]="{standalone: true}" [(ngModel)]="password" placeholder="Password" required>
									
								</div>

								<div class="form-group">
									<i class="fa fa-mobile fa-2x cus"></i>
									<input type="phone" class="form-control" id="signup-phone_number"
										[ngModelOptions]="{standalone: true}" [(ngModel)]="phone_number" placeholder="eg: +94770000000" required>
									
								</div>

								<div class="validation-message">{{signuperrormsg}}</div>

								<button type="submit" class="btn btn-primary btn-lg btn-block">Sign Up</button>

								<div class="bottom">
									<span class="helper-text m-b-10"><i class="fa fa-sign-in"></i> Have an
										account?&nbsp;<a [routerLink]="['/auth/signin']">Sign in</a></span>
								</div>
							</form>
						</div>
					</div>
				</div>
				<div class="col-md-1"></div>

			</div>

		</div>
	</div>

</div>