import { RouterModule } from '@angular/router';
import { AuthenticationComponent } from './authentication/authentication.component';
import { PageLoginComponent } from './page-login/page-login.component';
import { PageRegisterComponent } from './page-register/page-register.component';
import { PageConfirmSignUpComponent } from './page-confirm-sign-up/page-confirm-sign-up.component';
import { PageLockscreenComponent } from './page-lockscreen/page-lockscreen.component';
import { PageForgotPasswordComponent } from './page-forgot-password/page-forgot-password.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { PageForbiddonErrorComponent } from './page-forbiddon-error/page-forbiddon-error.component';
import { PageIsErrorComponent } from './page-is-error/page-is-error.component';
import { PageTryLaterComponent } from './page-try-later/page-try-later.component';
import { PageMaintananceComponent } from '../pages/page-maintanance/page-maintanance.component';
var ɵ0 = { title: 'Signin :: Inventra' }, ɵ1 = { title: 'Signup :: Inventra' }, ɵ2 = { title: 'Confirm Register :: Inventra' }, ɵ3 = { title: 'Lock Screen :: Inventra' }, ɵ4 = { title: 'Forgot Password :: Inventra' }, ɵ5 = { title: 'Page-404 :: Inventra' }, ɵ6 = { title: 'Page-403 :: Inventra' }, ɵ7 = { title: 'Page-500 :: Inventra' }, ɵ8 = { title: 'Page-503 :: Inventra' }, ɵ9 = { title: 'maintanance :: Inventra' };
var routes = [
    {
        path: '',
        component: AuthenticationComponent,
        children: [
            { path: '', redirectTo: 'auth/signin', pathMatch: 'full' },
            { path: 'auth/signin', component: PageLoginComponent, data: ɵ0 },
            { path: 'auth/signup', component: PageRegisterComponent, data: ɵ1 },
            { path: 'auth/confirm/:id', component: PageConfirmSignUpComponent, data: ɵ2 },
            { path: 'page-lockscreen', component: PageLockscreenComponent, data: ɵ3 },
            { path: 'auth/forgot', component: PageForgotPasswordComponent, data: ɵ4 },
            { path: 'page-404', component: PageNotFoundComponent, data: ɵ5 },
            { path: 'page-403', component: PageForbiddonErrorComponent, data: ɵ6 },
            { path: 'page-500', component: PageIsErrorComponent, data: ɵ7 },
            { path: 'page-503', component: PageTryLaterComponent, data: ɵ8 },
            { path: 'page-maintanance', component: PageMaintananceComponent, data: ɵ9 },
        ]
    }
];
export var routing = RouterModule.forChild(routes);
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6, ɵ7, ɵ8, ɵ9 };
