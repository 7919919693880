import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService} from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { Auth } from 'aws-amplify';

@Component({
  selector: 'app-page-forgot-password',
  templateUrl: './page-forgot-password.component.html',
  styleUrls: ['./page-forgot-password.component.css']
})
export class PageForgotPasswordComponent implements OnInit {
    email="";
    resetPassword:boolean=false;
    code="";
    password:"";
    forgotpassworderrormsg:string;

    constructor(private router: Router, private spinner: NgxSpinnerService, private toastr: ToastrService) { }

    ngOnInit() {
    }

    onSubmit() {
    
        var username = this.email;
        Auth.forgotPassword(username)
        .then(data => {
            console.log(data);
            this.resetPassword = true;
            this.forgotpassworderrormsg = "";
            this.toastr.success('code send successfully');
        })
        .catch(err => {
            console.log(err);
            this.forgotpassworderrormsg = err.message;
            //this.toastr.error(err.message,'Error');
        });
        
    }

    verifyPassword(){
        var username = this.email;
        var code = this.code;
        var new_password = this.password;
        Auth.forgotPasswordSubmit(username, code, new_password)
        .then(data => {
            console.log(data);
            this.toastr.success('Password reset successfully');
            this.router.navigate(['/auth/signin']);
        })
        .catch(err => {
            //console.log(err);
            //this.toastr.error(err.message,'Error');
            this.forgotpassworderrormsg = err.message;
        });
    }

    resendCode(){

        var username = this.email;
        Auth.forgotPassword(username)
        .then(data => {
            console.log(data);
            this.toastr.success('code resend successfully');
        })
        .catch(err => {
            console.log(err);
            //this.toastr.error(err.message,'Error');
            this.forgotpassworderrormsg = err.message;
        });

    }

}
