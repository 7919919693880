import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-page-maintanance',
  templateUrl: './page-maintanance.component.html',
  styleUrls: ['./page-maintanance.component.css']
})
export class PageMaintananceComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
