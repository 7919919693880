// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
	'aws_project_region': 'us-east-2',
	'aws_cognito_identity_pool_id': 'us-east-2:bb0600b1-6f19-48be-a4e5-b640af1e2672',
	'aws_cognito_region': 'us-east-2',
	'aws_user_pools_id': 'us-east-2_EBP4WY0HF',
	'aws_user_pools_web_client_id': 'ohsf9kl86gothu4010go3gdiu',

	'oauth': {
		'domain': 'invt-sample.auth.us-east-2.amazoncognito.com',
		'scope': [
			'phone',
			'email',
			'openid',
			'profile',
			'aws.cognito.signin.user.admin'
		],
		'redirectSignIn': 'https://inventra.net/auth/signin/',
		'redirectSignOut': 'https://inventra.net/auth/signin/',
		'responseType': 'code'
	},
	'federationTarget': 'COGNITO_USER_POOLS'
};


export default awsmobile;
