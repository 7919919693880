<div id="wrapper" id="signup-confirm">
		<div class="vertical-align-wrap">
			<div class="container" >	
				<div class="row">
					<div class="col-md-1"></div>
					<div class="col-md-10 col-sm-7 main-content">
						<div class="row">
							<div class="col-md-6 col-sm-6 leftImage">
							</div>
							<div class="col-md-6 col-sm-6 rightForm">
								
								<div class="col-12"><img src="/assets/images/signin_logo.jpg" width="100%" height=""></div>
								<div class="col-12 header">Confirm Sign Up</div>
								<form class="col-12 form-auth-small" #loginForm="ngForm" (ngSubmit)="onSubmit()" >
									<div class="form-group">
										<i class="fa fa-user fa-2x cus"></i>
										<input type="email" class="form-control" id="signup-confirm-email" [ngModelOptions]="{standalone: true}" [(ngModel)]="email" placeholder="Email" disabled="true">
									</div>
			
									<div class="form-group">
										<i class="fa fa-code fa-2x cus"></i>
										<input type="text" class="form-control" id="signup-confirm-code"  [ngModelOptions]="{standalone: true}" [(ngModel)]="code" placeholder="Confirm Code">
									</div>
								
									<button type="submit" class="btn btn-primary btn-lg btn-block">Confirm</button>
									
									<div class="bottom">
										<span class="helper-text m-b-10"><i class="fa fa-lock"></i> Lost your code?&nbsp;<a (click)="resendCode()" >Resend Code</a></span>
                  </div>
                  <div class="bottom">
                    <span class="helper-text m-b-10"><i class="fa fa-sign-in"></i> Back to sign in?&nbsp;<a [routerLink]="['/auth/signin']">Sign in</a></span>
                  </div>
								</form>
							</div>
						</div>
					</div>
					<div class="col-md-1"></div>
	
				</div>
					
			</div>
	</div>
	
	</div>
