import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { DatePipe } from '@angular/common'
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DataTablesModule } from 'angular-datatables';
import { AppComponent } from './app.component';
import { routing } from './app.routing';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { RichTextEditorAllModule } from '@syncfusion/ej2-angular-richtexteditor';
import { FullCalendarModule } from 'ng-fullcalendar';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { NgxGalleryModule } from 'ngx-gallery';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TagInputModule } from 'ngx-chips';
import { HttpClientModule } from '@angular/common/http';
import { NgxSpinnerModule } from "ngx-spinner";
import { AuthenticationModule } from '../app/authentication/authentication.module';
import { AmplifyAngularModule, AmplifyService } from 'aws-amplify-angular';
import { AuthGuardService } from '../app/auth-guard.service';
import { NgSelectModule } from '@ng-select/ng-select';

@NgModule({
    declarations: [
        AppComponent,
    ],
    imports: [
        BrowserModule,
        DataTablesModule,
        routing,
        NgbModule,
        BrowserAnimationsModule,
        ToastrModule.forRoot({
            timeOut:3000,
            positionClass:'toast-top-right',
            preventDuplicates: false,
        }),
        RichTextEditorAllModule,
        FullCalendarModule,
        NgMultiSelectDropDownModule,
        LeafletModule.forRoot(),
        NgxGalleryModule,
        FormsModule,
        ReactiveFormsModule,
        TagInputModule,
        HttpClientModule,
        NgxSpinnerModule,
        AuthenticationModule,
        AmplifyAngularModule,
        NgSelectModule
    ],
    providers: [
        DatePipe,
        AmplifyService,
        AuthGuardService
    ],
    bootstrap: [AppComponent]
   
})
export class AppModule { }
